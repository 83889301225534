import styled from '@emotion/styled'
import Link from 'next/link'
import ButtonLink from '../../ButtonLink'
import SignInButton from '../../SignInButton'

const Container = styled.header(({ theme }) => ({
	backgroundColor: theme.colors.gray300,
	borderBottom: `1px solid ${theme.colors.black}`,
}))

const Nav = styled.nav(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	width: '100%',
	maxWidth: theme.layout.maxPageWidth,
	margin: `0 auto`,
	// Add extra space for buttons’ hover borders.
	padding: `${theme.spacings.pagePadding / 2}px calc(${theme.spacings.pagePadding}px - ${theme.spacings.m})`,
}))

const TitleLink = styled(ButtonLink)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	padding: `${theme.spacings.s} ${theme.spacings.m}`,
}))

const Logo = styled.img(({ theme }) => ({
	width: '14px',
	objectFit: 'contain',
	marginRight: theme.spacings.s,
}))

export default function Header() {
	return (
		<Container role="banner">
			<Nav role="navigation" aria-label="Main">
				<Link href="/" passHref={true}>
					<TitleLink color="transparent">
						<Logo src="/klima-logo.svg" alt="" /> Klimanager
					</TitleLink>
				</Link>
				<SignInButton />
			</Nav>
		</Container>
	)
}
