import styled from '@emotion/styled'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Fragment } from 'react'

const MENU_ITEMS = [
	{
		category: 'Finances',
		items: [{ title: 'Offsets & Revenue', href: '/offsets' }],
	},
]

const Container = styled.aside(({ theme }) => ({
	width: `min(100%, 240px)`,
	padding: `${theme.spacings.pagePadding}px ${theme.spacings.m}`,
	borderRight: `1px solid ${theme.colors.gray500}`,
	backgroundColor: theme.colors.gray300,
}))

const Nav = styled.nav(() => ({
	display: 'flex',
	flexDirection: 'column',
}))

const Category = styled.div(({ theme }) => ({
	fontSize: theme.fontSizes.small,
	color: theme.colors.gray800,
	padding: `0 ${theme.spacings.m}`,
	marginBottom: theme.spacings.s,
}))

const Ul = styled.ul(() => ({
	listStyleType: 'none',
}))

const Li = styled.li(() => ({
	lineHeight: 1,
	'& + &': {
		marginTop: 2,
	},
}))

const A = styled.a<{ isActive: boolean }>(({ theme, isActive }) => ({
	display: 'block',
	whiteSpace: 'nowrap',
	textDecoration: 'none',
	padding: theme.spacings.m,
	borderRadius: theme.borderRadiuses.large,
	backgroundColor: isActive ? theme.colors.gray400 : undefined,
	border: `1px solid transparent`,
	'&:hover': {
		border: `1px solid ${isActive ? 'transparent' : theme.colors.gray500}`,
	},
}))

export default function Sidebar() {
	const router = useRouter()
	return (
		<Container>
			<Nav role="navigation" aria-label="Main">
				{MENU_ITEMS.map((menuItem, index) => (
					<Fragment key={index}>
						<Category>{menuItem.category}</Category>
						<Ul>
							{menuItem.items.map((item) => {
								const isActive = router.route.startsWith(item.href)
								return (
									<Li key={item.href}>
										<Link href="/offsets" passHref={true}>
											<A isActive={isActive}>{item.title}</A>
										</Link>
									</Li>
								)
							})}
						</Ul>
					</Fragment>
				))}
			</Nav>
		</Container>
	)
}
