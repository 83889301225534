import Auth from '@aws-amplify/auth'
import { Hub, HubCallback } from '@aws-amplify/core'
import { useEffect, useState } from 'react'

export default function useLoggedIn() {
	const [isLoading, setLoading] = useState(true)
	const [isLoggedIn, setLoggedIn] = useState(false)
	useEffect(() => {
		const handleAuthChange: HubCallback = ({ payload: { event } }) => {
			switch (event) {
				case 'signIn':
				case 'signOut':
				case 'cognitoHostedUI':
					checkLoggedIn()
					break
				// case 'signIn_failure':
				// case 'cognitoHostedUI_failure':
				// 	break
			}
		}
		const checkLoggedIn = async () => {
			try {
				await Auth.currentAuthenticatedUser()
				setLoggedIn(true)
			} catch {
				setLoggedIn(false)
			} finally {
				setLoading(false)
			}
		}
		Hub.listen('auth', handleAuthChange)
		checkLoggedIn()
		return () => Hub.remove('auth', handleAuthChange)
	}, [])
	return { isLoading, isLoggedIn }
}
