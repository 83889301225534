import Head from 'next/head'

type Props = {
	pageTitle?: string
}

export default function AppHead({ pageTitle }: Props) {
	const fullPageTitle = [pageTitle, 'Klimanager'].filter(Boolean).join(' – ')
	return (
		<Head>
			<meta name="viewport" content="width=device-width, initial-scale=1.0" />
			<title>{fullPageTitle}</title>
		</Head>
	)
}
