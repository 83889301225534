import styled from '@emotion/styled'
import Button from '../components/Button'
import useLoggedIn from '../hooks/useLoggedIn'
import { signIn, signOut } from '../services/auth'

const StyledButton = styled(Button)(({ theme }) => ({
	padding: `${theme.spacings.s} ${theme.spacings.m}`,
}))

export default function SignInButton() {
	const { isLoggedIn, isLoading } = useLoggedIn()
	return isLoggedIn ? (
		<StyledButton onClick={signOut} color="transparent" disabled={isLoading}>
			Log out
		</StyledButton>
	) : (
		<StyledButton onClick={signIn} color="transparent" disabled={isLoading}>
			Log in
		</StyledButton>
	)
}
