import styled, { CSSObject } from '@emotion/styled'
import React, { ButtonHTMLAttributes, forwardRef, ReactNode } from 'react'
import { Theme } from '../services/theme'

type ButtonColor = 'white' | 'transparent'
type ButtonKind = 'regular' | 'raw'

type StyleMapping = {
	basic: CSSObject
	hover: CSSObject
	focus?: CSSObject
}

function getColorAttributes(theme: Theme, color: ButtonColor): StyleMapping {
	const COLORS: Record<ButtonColor, StyleMapping> = {
		white: {
			basic: {
				color: theme.colors.black,
				backgroundColor: theme.colors.pageBackground,
				border: `1px solid ${theme.colors.black}`,
				boxShadow: theme.boxShadows.button,
			},
			hover: {
				color: theme.colors.black,
				backgroundColor: theme.colors.pageBackground,
				border: `1px solid ${theme.colors.black}`,
				boxShadow: theme.boxShadows.buttonHover,
				transform: `translateY(1px)`,
			},
			focus: {
				border: `1px solid ${theme.colors.blue}`,
				boxShadow: theme.boxShadows.inputFocus,
			},
		},
		transparent: {
			basic: {
				color: theme.colors.black,
				backgroundColor: 'transparent',
				border: `1px solid transparent`,
			},
			hover: {
				color: theme.colors.black,
				backgroundColor: 'transparent',
				border: `1px solid ${theme.colors.gray500}`,
			},
		},
	}
	return COLORS[color]
}

function getSizeAttributes(theme: Theme, kind: ButtonKind): CSSObject {
	const KINDS: Record<ButtonKind, CSSObject> = {
		regular: {
			padding: `${theme.spacings.buttonPaddingVertical} ${theme.spacings.buttonPaddingHorizontal}`,
		},
		raw: { padding: 0 },
	}
	return KINDS[kind]
}

const ButtonIconWrapper = styled.div({
	flex: '0 0 1em',
	marginRight: '0.5em',
})

type ButtonProps = {
	color?: ButtonColor
	kind?: ButtonKind
	icon?: ReactNode
	children: ReactNode
}

type AllButtonProps = ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>

export const StyledButton = styled.button<AllButtonProps>(
	({ theme, color = 'white', kind = 'regular', icon, disabled }) => {
		const { basic: colorAttributes, hover: colorHoverAttributes, focus: colorFocusAttributes } = getColorAttributes(
			theme,
			color
		)
		return {
			display: icon ? 'inline-flex' : 'inline-block',
			textAlign: 'center',
			fontFamily: theme.fontFamilies.main,
			fontSize: theme.fontSizes.default,
			fontWeight: theme.fontWeights.bold,
			lineHeight: 1,
			border: 'none',
			borderRadius: theme.borderRadiuses.default,
			textDecoration: 'none',
			margin: 0,
			whiteSpace: 'nowrap',
			opacity: disabled ? 0.5 : 1,
			outline: 'none',
			...colorAttributes,
			...getSizeAttributes(theme, kind),
			'&:hover': {
				cursor: disabled ? 'initial' : 'pointer',
				...(disabled ? {} : colorHoverAttributes),
			},
			'&:focus': {
				...colorFocusAttributes,
			},
		}
	}
)

const Button = forwardRef<HTMLButtonElement, AllButtonProps>(
	({ type = 'button', children, icon, ...otherProps }, ref) => (
		<StyledButton type={type} icon={icon} {...otherProps} ref={ref}>
			{icon && <ButtonIconWrapper>{icon}</ButtonIconWrapper>}
			{children}
		</StyledButton>
	)
)

export default Button
