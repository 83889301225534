import styled from '@emotion/styled'
import { MouseEvent, ReactNode, useCallback } from 'react'
import useLoggedIn from '../../hooks/useLoggedIn'
import { signIn } from '../../services/auth'
import Link from '../Link'
import AppHead from './AppHead'
import Header from './Header'
import Sidebar from './Sidebar'

const HEADER_HEIGHT = '46px'

const Content = styled.div(({ theme }) => ({
	display: 'flex',
	width: `min(100%, ${theme.layout.maxPageWidth}px)`,
	minHeight: `calc(100vh - ${HEADER_HEIGHT})`,
	overflow: 'auto',
	margin: `0 auto`,
}))

const Main = styled.main(({ theme }) => ({
	width: '100%',
	padding: theme.spacings.pagePadding,
}))

type Props = {
	pageTitle?: string
	children: ReactNode
	requiresLogin?: boolean
}

export default function AppPage({ pageTitle, children, requiresLogin }: Props) {
	const { isLoggedIn } = useLoggedIn()
	const accessDenied = requiresLogin && !isLoggedIn
	const handleSignIn = useCallback((event: MouseEvent) => {
		event.preventDefault()
		signIn()
	}, [])
	return (
		<>
			<AppHead pageTitle={pageTitle} />
			<Header />
			<Content>
				<Sidebar />
				<Main role="main">
					{accessDenied ? (
						<>
							Please{' '}
							<Link href="" onClick={handleSignIn}>
								log in
							</Link>{' '}
							to access this page.
						</>
					) : (
						children
					)}
				</Main>
			</Content>
		</>
	)
}
