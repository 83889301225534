import styled from '@emotion/styled'
import React, { forwardRef, HTMLProps } from 'react'

type LinkProps = {
	openInNewTab?: boolean
}

type Props = HTMLProps<HTMLAnchorElement> & LinkProps

const StyledA = styled.a(({ theme }) => ({
	fontFamily: theme.fontFamilies.main,
	color: theme.colors.black,
	textDecoration: 'underline',
	'&:hover': {
		textDecoration: 'none',
	},
}))

const Link = forwardRef<HTMLAnchorElement, Props>(({ openInNewTab = false, ...otherProps }, ref) => {
	return (
		<StyledA
			{...otherProps}
			target={openInNewTab ? '_blank' : otherProps.target}
			rel={openInNewTab ? 'noopener noreferrer' : otherProps.rel}
			ref={ref}
			as="a"
		/>
	)
})

export default Link
